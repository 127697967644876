(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

/*
 * Slider Highlights
 * inizializzato solo su mobile
 */
var breakpoint = window.matchMedia("(min-width:768px)");
var sliderHighlights;
var articlesSlider;
var categorieSlider;
var productsHpSlider;

var breakpointChecker = function breakpointChecker() {
  if (breakpoint.matches === true) {
    if (sliderHighlights !== undefined) sliderHighlights.destroy(true, true);
    if (articlesSlider !== undefined) articlesSlider.destroy(true, true);
    if (categorieSlider !== undefined) categorieSlider.destroy(true, true);
    if (productsHpSlider !== undefined) productsHpSlider.destroy(true, true);
    return;
  } else if (breakpoint.matches === false) {
    if (document.querySelector('.highlights')) {
      enableSwiper('highlights', document.querySelector('.highlights'));
    }

    if (document.querySelector('.articles-list')) {
      enableSwiper('articles', document.querySelector('.articles-list .swiper-container'));
    }

    if (document.querySelector('.supporto-categorie')) {
      enableSwiper('categories', document.querySelector('.supporto-categorie .swiper-container'));
    }

    if (document.querySelector('.prodotti-hp')) {
      var sliders = document.querySelectorAll('.prodotti-hp__slider');
      Array.prototype.forEach.call(sliders, function (slider, i) {
        enableSwiper('productsHp', slider);
      });
    }
  }
};

var enableSwiper = function enableSwiper(slider, el) {
  switch (slider) {
    case 'highlights':
      sliderHighlights = new Swiper(el, {
        loop: false,
        navigation: false,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 15
      });
      break;

    case 'articles':
      articlesSlider = new Swiper(el, {
        loop: false,
        navigation: false,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 15
      });
      break;

    case 'categories':
      categorieSlider = new Swiper(el, {
        loop: false,
        navigation: false,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 15
      });
      break;

    case 'productsHp':
      productsHpSlider = new Swiper(el, {
        loop: false,
        navigation: false,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 15,
        observer: true,
        observeParents: true
      });
      break;
  }
};

breakpoint.addListener(breakpointChecker);
breakpointChecker();
/*
 * Slider loghi
 */

var sliderLoghi = document.querySelector('.slider-loghi .swiper-container');

var _sliderLoghi = new Swiper(sliderLoghi, {
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 15,
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 30,
      centeredSlides: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }
});

},{}]},{},[1]);
